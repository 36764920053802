<template>
  <div class="app-container">
    <el-row>
      <el-col :span="6">
        <el-button
          icon="el-icon-plus"
          size="small"
          type="primary"
          @click="allotUser"
          >{{ $t("tenant.allotUser") }}</el-button
        >
      </el-col>
      <el-col :span="18">
        <div class="filter-container" align="right">
          <tenant-select
            style="width: 200px"
            size="small"
            class="filter-item"
            @change="queryUser"
            v-model="users.listQuery.tenantId"
          ></tenant-select>
          <account-select
            style="width: 200px"
            size="small"
            class="filter-item"
            v-model="users.listQuery.userAccount"
            model="1"
          ></account-select>
          <el-button
            class="filter-item"
            type="primary"
            size="small"
            icon="el-icon-search"
            :loading="loading"
            @click="queryUser"
            >{{ $t("commons.search") }}</el-button
          >
        </div>
      </el-col>
    </el-row>

    <el-table
      v-loading="loading"
      :data="users.list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      stripe
    >
      <el-table-column
        type="index"
        align="center"
        :label="$t('commons.index')"
        width="95"
      >
      </el-table-column>
      <el-table-column :label="$t('tenant.tenantName')" width="220">
        <template slot-scope="scope">
          <span>{{ scope.row.tenantName }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('userManager.userAccount')" width="220">
        <template slot-scope="scope">
          <span>{{ scope.row.userAccount }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="userName" :label="$t('userManager.userName')">
        <template slot-scope="scope">
          <span>{{ scope.row.userName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="zoneCode"
        :label="$t('userManager.zoneCode')"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.zoneCode }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="phone" :label="$t('userManager.phone')">
        <template slot-scope="scope">
          <span>{{ scope.row.phone }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="email" :label="$t('userManager.email')">
        <template slot-scope="scope">
          <span>{{ scope.row.email }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :label="$t('commons.actions')"
        width="305"
      >
        <template slot-scope="scope">
          <div class="ruge-buttons">
            <el-button type="text" @click="allotRole(scope.row)">{{
              $t("permission.allotRole")
            }}</el-button>
            <el-button type="text" @click="allotDataRange(scope.row)">{{
              $t("permission.allotDataRange")
            }}</el-button>
            <el-button type="text" @click="viewToken(scope.row)">{{
              $t("permission.viewToken")
            }}</el-button>
            <el-button
              type="text"
              style="color: #ff0000"
              @click="deleteMountUser(scope.row)"
              >{{ $t("commons.delete") }}</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="users.total > 0"
      :total="users.total"
      :page.sync="users.listQuery.current"
      :limit.sync="users.listQuery.rowCount"
      @pagination="getTenantUser"
    />
    <!-- 查看令牌 -->
    <el-dialog :visible.sync="users.tokenVisible" width="30%">
      <template slot="title">
        <title-icon />{{ $t("permission.viewToken") }}
      </template>
      <template v-if="users.token && users.token !== ''">
        <el-input
          type="textarea"
          autosize
          readonly
          v-model="users.token"
        ></el-input>
        <div style="margin-top: 8px">
          <span
            ><i class="el-icon-info"></i>
            {{ $t("permission.tokenExpire") }}</span
          ><label>{{
            users.tokenExpire | dateFormat("YYYY-MM-DD HH:mm:ss")
          }}</label>
        </div>
      </template>
      <template v-else>
        <h3 style="text-align: center; word-break: break-word">
          {{ $t("userManager.resetTokenTips") }}
        </h3>
      </template>
      <div slot="footer">
        <el-dropdown @command="resetToken" v-loading="tokenLoading">
          <el-button type="primary">
            {{ $t("permission.resetToken")
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="720">{{
              $t("commons.hour720")
            }}</el-dropdown-item>
            <el-dropdown-item command="2160">{{
              $t("commons.hour2160")
            }}</el-dropdown-item>
            <el-dropdown-item command="4320">{{
              $t("commons.hour4320")
            }}</el-dropdown-item>
            <el-dropdown-item command="8760">{{
              $t("commons.hour8760")
            }}</el-dropdown-item>
            <el-dropdown-item command="26280">{{
              $t("commons.hour26280")
            }}</el-dropdown-item>
            <el-dropdown-item command="43800">{{
              $t("commons.hour43800")
            }}</el-dropdown-item>
            <el-dropdown-item command="87600">{{
              $t("commons.hour87600")
            }}</el-dropdown-item>
            <el-dropdown-item command="262800">{{
              $t("commons.hour262800")
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <template v-if="users.token && users.token !== ''">
          <el-button
            type="success"
            :loading="tokenLoading"
            style="margin-left: 10px"
            @click="validToken()"
            >{{ $t("permission.validToken") }}</el-button
          >
        </template>
      </div>
    </el-dialog>
    <!-- 拉取用户 -->
    <el-dialog :visible.sync="allotUserVisible" width="400px">
      <template slot="title">
        <title-icon />{{ $t("tenant.allotUser") }}
      </template>
      <user-allot @cancle="cancelAllotUser"></user-allot>
    </el-dialog>
  </div>
</template>

<script>
let manager = require("@/api/ruge/tenant/tenant.js");
let user = require("@/api/ruge/security/user.js");
import Pagination from "@/components/Pagination";
import TenantSelect from "@/components/TenantSelect";
import AccountSelect from "@/components/AccountSelect";
import UserAllot from "@/views/ruge/grant/userAllot";

export default {
  name: "TenantSysGrant",
  components: {
    Pagination,
    TenantSelect,
    AccountSelect,
    UserAllot,
    // Role,
    // Program,
  },
  data() {
    return {
      loading: true,
      tokenLoading: false,
      allotUserVisible: false,
      users: {
        list: null,
        total: 0,
        listQuery: {
          current: 1,
          rowCount: 10,
          tenantId: -1,
          userAccount: null,
        },
        tokenVisible: false,
        tokenExpire: null,
        token: "",
      },
      currentRow: {
        userAccount: "",
        userId: null,
        tenantId: null,
        // 重置令牌有效期(单位:小时)，默认365天
        duration: 365 * 24,
      },
    };
  },
  created() {
    this.getUrlQuerys();
    this.getTenantUser();
  },
  computed: {
    userTab() {
      return defineTab.userTab;
    },
  },
  methods: {
    getUrlQuerys() {
      //获取连接上携带的参数
      const qData = this.$route.query;
      for (let key in qData) {
        qData[key] && this.updateSingleKey(key, qData);
      }
      this.queryUser();
    },
    updateSingleKey(key, datas) {
      const value = datas[key];
      switch (key) {
        case "current":
          this.users.listQuery.current = Number(value);
          break;
        case "rowCount":
          this.users.listQuery.rowCount = Number(value);
          break;
        case "tenantId":
          this.users.listQuery.tenantId = Number(value);
          break;
        case "userAccount":
          this.users.listQuery.userAccount = value;
          break;
      }
    },
    getTenantUser() {
      this.loading = true;
      manager
        ._sys_getMountAccounts(this.users.listQuery)
        .then((response) => {
          this.users.list = response.rows;
          this.users.total = response.total;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(`查询失败，原因 => ${error}`);
        });
    },
    queryUser() {
      this.users.listQuery.current = 1;
      let str = "";
      let dataObj = { ...this.$route.query, ...this.users.listQuery };
      for (let keys in dataObj) {
        let val = dataObj[keys];
        if (keys && val) {
          str += keys + "=" + val + "&";
        }
      }
      this.$router.push(this.$route.path + "?" + str);
      this.getTenantUser();
    },
    // 删除挂载用户
    deleteMountUser(row) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          // 调用服务
          manager
            ._sys_deleteTenantMount(row)
            .then(() => {
              // 成功提示
              this.$message({
                type: "success",
                message: this.$t("message.deleteSuccess"),
              });
              if (this.users.total % this.users.listQuery.rowCount == 1) {
                this.users.listQuery.current = this.users.listQuery.current - 1;
              }
              // 刷新列表
              this.getTenantUser();
            })
            .catch(() => {});
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    allotUser() {
      this.allotUserVisible = true;
    },
    cancelAllotUser(refresh) {
      this.allotUserVisible = false;
      if (refresh) {
        this.queryUser();
      }
    },
    //查看令牌
    viewToken(row) {
      this.users.tokenVisible = true;
      this.currentRow.userAccount = row.userAccount;
      this.currentRow.userId = row.userId;
      this.currentRow.tenantId = row.tenantId;
      user.getToken(this.currentRow).then((resp) => {
        this.users.token = resp.result;
        this.users.tokenExpire = parseInt(resp.exipreTime);
      });
    },
    // 重置令牌
    resetToken(tokenDuration) {
      this.$confirm(
        this.$t("userManager.resetTokenConfirm"),
        this.$t("userManager.resetTokenConfirmTitle"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.tokenLoading = true;
          // 重置令牌有效期(单位:小时)
          if (tokenDuration) {
            this.currentRow.duration = Number(tokenDuration);
          } else {
            // 默认365天
            this.currentRow.duration = 8760;
          }
          user
            .resetToken(this.currentRow)
            .then(() => {
              this.viewToken(this.currentRow);
              // 成功提示
              this.$message({
                message: this.$t("message.operationSuccess"),
                type: "success",
              });
            })
            .finally(() => {
              this.tokenLoading = false;
            });
        })
        .catch((error) => {
          console.log(`未重置，原因 => ${error}`);
        });
    },
    //验证令牌
    validToken() {
      this.tokenLoading = true;
      user
        .validToken({ token: this.users.token })
        .then((resp) => {
          if (resp.checkResult) {
            this.users.tokenExpire = resp.exipreTime;
            this.$message({
              message: this.$t("message.validSuccess"),
              type: "success",
            });
          } else {
            this.$message.error(this.$t("message.validFail"));
          }
        })
        .finally(() => {
          this.tokenLoading = false;
        });
    },
    // 分配权限
    allotRole(row) {
      this.$router.push({
        path: "/sys/permission/grant/allotRole",
        query: {
          userAccount: row.userAccount,
          userId: row.userId,
          tenantId: row.tenantId,
        },
      });
    },
    //分配数据权限
    allotDataRange(row) {
      this.$router.push({
        path: "/sys/permission/grant/allotData",
        query: {
          userAccount: row.userAccount,
          userId: row.userId,
          tenantId: row.tenantId,
        },
      });
    },
  },
};
</script>
