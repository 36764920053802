var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "tenantForm",
          attrs: {
            "label-width": "80px",
            model: _vm.tenant.form,
            rules: _vm.tenant.formRule,
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("tenant.tenantName"),
                        prop: "tenantId",
                      },
                    },
                    [
                      _c("tenant-select", {
                        model: {
                          value: _vm.tenant.form.tenantId,
                          callback: function ($$v) {
                            _vm.$set(_vm.tenant.form, "tenantId", $$v)
                          },
                          expression: "tenant.form.tenantId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("userManager.userAccount"),
                        prop: "userAccount",
                      },
                    },
                    [
                      _c("account-select", {
                        attrs: { model: "1" },
                        on: { change: _vm.selectAccount },
                        model: {
                          value: _vm.tenant.form.userAccount,
                          callback: function ($$v) {
                            _vm.$set(_vm.tenant.form, "userAccount", $$v)
                          },
                          expression: "tenant.form.userAccount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer", align: "right" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.cancel()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("commons.cancel")))]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.allotAccount()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("commons.save")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }