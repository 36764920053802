<template>
  <div>
    <el-form
      label-width="80px"
      :model="tenant.form"
      ref="tenantForm"
      :rules="tenant.formRule"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item :label="$t('tenant.tenantName')" prop="tenantId">
            <tenant-select v-model="tenant.form.tenantId"></tenant-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            :label="$t('userManager.userAccount')"
            prop="userAccount"
          >
            <account-select
              v-model="tenant.form.userAccount"
              model="1"
              @change="selectAccount"
            ></account-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" align="right">
      <el-button @click="cancel()">{{ $t("commons.cancel") }}</el-button>
      <el-button type="primary" @click="allotAccount()">{{
        $t("commons.save")
      }}</el-button>
    </div>
  </div>
</template>

<script>
let manager = require("@/api/ruge/tenant/tenant.js");
import TenantSelect from "@/components/TenantSelect";
import AccountSelect from "@/components/AccountSelect";

export default {
  components: {
    TenantSelect,
    AccountSelect,
  },
  data() {
    return {
      tenant: {
        form: {
          userAccount: null,
          tenantId: -1,
          userId: null,
        },
        formRule: {
          userAccount: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          tenantId: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
        },
      },
    };
  },
  methods: {
    allotAccount() {
      this.$refs.tenantForm.validate((valid) => {
        if (valid) {
          manager._sys_mountAccount(this.tenant.form).then((rsp) => {
            if (rsp > 0) {
              this.$message({
                type: "success",
                message: this.$t("message.saveSuccess"),
              });
              this.cancel(true);
            } else {
              this.$message({
                type: "error",
                message: this.$t("tenant.message.existUser"),
              });
            }
          });
        }
      });
    },
    selectAccount(record) {
      this.tenant.form.userId = record.userId;
    },
    cancel(cbk) {
      this.tenant.form.userAccount = null;
      this.tenant.form.tenantId = null;
      this.$refs.tenantForm.resetFields();
      this.$emit("cancle", cbk);
    },
  },
};
</script>
